import { Container, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';
import * as React from 'react';
import { StateContext } from '../contexts/state/State';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ErrorDialog({ open, onCloses, errors }) {
  const [state, dispatch] = React.useContext(StateContext);
  const { error } = state;
  if (!error) {
    return <Box />;
  }
  const onClose = () => {
    dispatch({ type: 'error', error: null });
  };

  return (
    <Dialog
      open={error ?? false}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Container maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <img
              alt="error-icon"
              src="/static/illustrations/error.png"
              style={{ maxHeight: 200, margin: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h4" color="error">
              OH NO!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {error.message}
              </DialogContentText>
              <Typography>{`Reference: #${error.id}`}</Typography>
            </DialogContent>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
}
