import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { API_URL } from '../../constants';

export default function ProductInventoryDialog({
  open,
  onClose,
  event,
  title,
  subtitle,
  product,
  fetchProduct
}) {
  const [date, setDate] = useState(new Date(Date.now()));
  const [amount, setAmount] = useState(0);

  const onSubmit = () => {
    fetch(`${API_URL}/inventory`, {
      method: 'POST',
      body: JSON.stringify({
        product_id: product.id,
        timestamp: date.toISOString(),
        amount: parseInt(amount, 10),
        event,
        stock_before_event: product.inventory
      }),
      headers: {
        token: window.localStorage.getItem('finca_admin_token'),
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          fetchProduct();
          setDate(new Date(Date.now()));
          setAmount(0);
          onClose();
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="subtitle1" color="text.secondary">
            {subtitle}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Timestamp"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
            />
          </LocalizationProvider>
          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(val) => setAmount(val.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
