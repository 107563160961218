import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { API_URL } from '../../constants';
import { fDateTime } from '../../utils/formatTime';

export default function LogPage() {
  const [log, setLog] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/log`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => setLog(json.reverse()));
        }
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <Page title="Log | Finca Naundrup">
      <Container>
        <Typography variant="h3">Log</Typography>
        <Typography variant="subtitle2">Latest 100 lines of the log file</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {log.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.level}</TableCell>
                <TableCell>{item.msg}</TableCell>
                <TableCell>{fDateTime(item.time)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Page>
  );
}
