import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { API_URL } from '../../../constants';
import { UserContext } from '../../../contexts/user/User';
import ErrorDialog from '../../ErrorDialog';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [user, dispatch] = useContext(UserContext);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (value) => {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        body: JSON.stringify({
          email: value.email,
          password: value.password
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        response.json().then((json) => {
          window.localStorage.setItem('finca_admin_token', json.token);
          window.localStorage.setItem('user', JSON.stringify(json.user));
          dispatch({ type: 'set', user: jwtDecode(json.token) });
          navigate('/', { replace: true });
        });
      } else if (response.status === 401) {
        setError({
          message: 'Invalid credentials',
          id: '0401'
        });
      }
      // .then((response) => {
      //   if (response.ok) {
      //     response.json().then((json) => {
      //       window.localStorage.setItem('finca_admin_token', json.token);
      //       window.localStorage.setItem('user', JSON.stringify(json.user));
      //       navigate('/dashboard', { replace: true });
      //     });
      //   }
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
      <ErrorDialog open={error !== null} onClose={() => setError(null)} error={error} />
    </FormikProvider>
  );
}
