import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CheckoutDialog from '../CheckoutDialog';

export default function ConfigureCheckout({ customer, fetchCustomer }) {
  const [value, setValue] = useState(new Date());
  const [checkout, setCheckout] = useState(false);
  return (
    <Box>
      <Typography component="span" variant="h5" color="text.secondary" sx={{ mb: 2 }}>
        Set checkout
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
        <Grid item md={3} xs={12}>
          <Button variant="contained" onClick={() => setCheckout(true)}>
            Set checkout
          </Button>
        </Grid>
        <Grid item md={9} xs={12} sx={{ textAlign: 'end' }}>
          <Typography
            component="span"
            variant="body2"
            color="text.secondary"
            sx={{ fontStyle: 'italic', textAlign: 'end' }}
          >
            Set a selected date as the latest checkout time <br /> **Must not be a date before the
            latest checkout**
          </Typography>
        </Grid>
      </Grid>
      <CheckoutDialog
        open={checkout === undefined ? false : checkout}
        onClose={() => {
          fetchCustomer();
          setCheckout(false);
        }}
        customerId={customer.id}
        to={value}
        from={customer.last_checkout}
      />
    </Box>
  );
}
