import uploadOutlined from '@iconify/icons-ant-design/upload-outlined';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Card, CardMedia, CircularProgress, styled } from '@mui/material';
import { Box } from '@mui/system';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { storage } from '../../firebase';

const Input = styled('input')({
  display: 'none'
});

export default function ProductEditImage({ image, setImage }) {
  const [loading, setLoading] = useState(false);
  const uploadImage = (file) => {
    setLoading(true);
    uploadBytes(ref(storage, file.name), file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImage(url);
        setLoading(false);
      });
    });
  };
  return (
    <Box>
      <Card sx={{ p: 2, mb: 2, position: 'relative' }}>
        <Box>
          <CardMedia
            image={image || '/static/icons/image.svg'}
            component="img"
            height="300"
            sx={{ objectFit: 'contain', opacity: loading ? 0.5 : 1 }}
          />
          {loading ?? (
            <CircularProgress
              sx={{
                position: 'absolute',
                zIndex: 10,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto'
              }}
            />
          )}
        </Box>
      </Card>

      <label htmlFor="contained-button-file">
        <Input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={(file) => uploadImage(file.target.files[0])}
          onClick={(val) => console.log(val)}
          onBlurCapture={(val) => console.log(val)}
          onPointerCancel={(val) => console.log(val)}
        />
        <LoadingButton
          startIcon={<Icon icon={uploadOutlined} />}
          color="warning"
          variant="contained"
          fullWidth
          loading={loading}
          //   sx={{
          //     margin: 'auto',
          //     textAlign: 'center'
          //   }}
          component="span"
        >
          Upload
        </LoadingButton>
      </label>
    </Box>
  );
}
