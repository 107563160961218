// routes
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
// components
import ScrollToTop from './components/ScrollToTop';
import { StateProvider } from './contexts/state/State';
import { UserProvider } from './contexts/user/User';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <UserProvider>
        <StateProvider>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Router />
        </StateProvider>
      </UserProvider>
    </ThemeConfig>
  );
}
