// material
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
// components
import Page from '../components/Page';
import {
  AppBugReports,
  AppItemOrders,
  AppNewUsers,
  AppOrderTimeline,
  AppWebsiteVisits,
  AppWeeklySales
} from '../components/_dashboard/app';
import { API_URL } from '../constants';
import { StateContext } from '../contexts/state/State';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [state, dispatch] = useContext(StateContext);
  const { dashboard } = state;
  useEffect(() => {
    fetchDashboard();
  }, []);

  const fetchDashboard = () => {
    fetch(`${API_URL}/dashboard`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => dispatch({ type: 'dashboard', dashboard: json }));
        }
      })
      .catch((e) => console.log(e));
  };

  if (!dashboard) {
    return <CircularProgress />;
  }

  return (
    <Page title="Dashboard | Finca Naundrup">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales title="Sales today" value={dashboard.sales_today} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers title="Revenue today" value={dashboard.revenue_today} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports title="Active visitors" value={dashboard.active} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders
              title="Online devices"
              value={`${dashboard.devices.filter((device) => device.is_active).length}/${dashboard.devices.length
                }`}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <AppNewsUpdate title="Latest orders" list={dashboard.orders} />
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline title="Latest orders" list={dashboard.orders} />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits list={dashboard.monthly_sales_by_category} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>


          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
