import jwtDecode from 'jwt-decode';

export const reducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return action.user;
    }
    case 'clear':
      window.localStorage.removeItem('finca_admin_token');
      return { ...state, user: null };
    default:
      return state;
  }
};
const initial = window.localStorage.getItem('finca_admin_token');
export const initialState = initial ? jwtDecode(initial) : {};
