import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { API_URL_V2 } from '../../constants';
import TaskItem from './TaskItem';

export default function CheckoutDialogConfirm({
  customerId,
  from,
  setFromPicker,
  setToPicker,
  to,
  formik,
  orders,
  setOrders
}) {
  const [totalPrice, setTotalPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrders(from, to);
  }, []);

  useEffect(() => {
    // Get total price
    getTotalPrice();
  }, [orders]);

  const getTotalPrice = () => {
    setTotalPrice(null);
    let temp = 0;
    orders.forEach((element) => {
      temp += element.total_price;
    });
    setTotalPrice(temp);
  };

  const fetchOrders = (fromDate, toDate) => {
    setLoading(true);
    fetch(`${API_URL_V2}/orders?customer_id=${customerId}&created_at=${fromDate},${toDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setOrders(json.items)
          setLoading(false);
        });
      } else {
        setError({ id: 4001, message: 'Failed to fetch orders' });
      }
    }).catch((e) => {
      console.error(e)
      setError(e);
    });
  };

  const updateOrders = (val, index) => {
    const newState = [...orders];
    newState[index] = val;
    setOrders(newState);
  };

  const { handleSubmit } = formik;

  if (error) {
    return <DialogContent>
      <Typography variant="h4">Error: {error.message ?? error}</Typography>
    </DialogContent>
  }

  if (loading) {
    return <DialogContent>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
        <CircularProgress />
      </Box>
    </DialogContent>
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h4">Confirm checkout</Typography>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="From"
                  value={new Date(from)}
                  format="dd/MM/yyyy - HH:mm"
                  ampm={false}
                  onChange={(newValue) => {
                    setFromPicker(newValue);
                    fetchOrders(newValue, to);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="To"
                  value={new Date(to)}
                  format="dd/MM/yyyy - HH:mm"
                  ampm={false}
                  onChange={(newValue) => {
                    setToPicker(newValue);
                    fetchOrders(from, newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          {orders.map((task, index) => (
            <TaskItem
              key={task.id}
              task={task}
              formik={formik}
              index={index}
              updateTask={(val) => updateOrders(val, index)}
            />
          ))}
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
            <Typography variant="h4">Total price: </Typography>
            {totalPrice !== null ? (
              <Typography variant="h4">{`${totalPrice}€`}</Typography>
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
