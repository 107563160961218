import { Box, Container, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import Page from '../components/Page';
import { AccountProfile } from '../components/account/account-profile';
import { AccountProfileDetails } from '../components/account/account-profile-details';
import { API_URL } from '../constants';
import { UserContext } from '../contexts/user/User';

export default function Profile() {
  const [curr, dispatch] = useContext(UserContext);
  const [user, setUser] = useState(curr);

  const handleChange = (val) => {
    const temp = { ...user };
    temp[val.target.name] = val.target.value;
    setUser(temp);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    fetch(`${API_URL}/user`, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            window.localStorage.setItem('finca_admin_token', json.token);
            dispatch({ type: 'set', user: json.user });
          });
        }
      })
      .catch((e) => console.log(e));
  };
  const onPasswordSubmit = (e) => {
    e.preventDefault();
    if (
      user.newPassword &&
      user.newPasswordConfirm &&
      user.newPassword === user.newPasswordConfirm
    ) {
      console.log(user);
    }
  };
  return (
    <Page>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Profile
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountProfile user={user} />
              <Box
                sx={{
                  width: '100%',
                  mt: 1
                }}
              >
                {/* <Button sx={{ width: '100%' }} color="error" variant="outlined">
                  Delete profile
                </Button> */}
              </Box>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountProfileDetails user={user} handleChange={handleChange} onSubmit={onSubmit} />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Box />
            </Grid>
            {/* <Grid item lg={8} md={6} xs={12}>
              <AccountProfilePassword
                user={user}
                handleChange={handleChange}
                onSubmit={onPasswordSubmit}
              />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </Page>
  );
}
