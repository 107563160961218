import tabletFilled from '@iconify/icons-ant-design/tablet-filled';
import peopleFill from '@iconify/icons-eva/people-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import baselineAdminPanelSettings from '@iconify/icons-ic/baseline-admin-panel-settings';
import baselinePointOfSale from '@iconify/icons-ic/baseline-point-of-sale';
import baselineReceipt from '@iconify/icons-ic/baseline-receipt';
import { Icon } from '@iconify/react';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Visitors',
    path: '/visitors',
    icon: getIcon(peopleFill),
    children: [
      {
        title: 'Billing',
        path: '/visitors/billing',
        icon: getIcon(baselineReceipt)
      },
      {
        title: 'List all',
        path: '/visitors/customers',
        icon: getIcon(baselineReceipt)
      }
    ]
  },
  {
    title: 'products',
    path: '/products',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'devices',
    path: '/devices',
    icon: getIcon(tabletFilled)
  },
  {
    title: 'sales',
    path: '/sales',
    icon: getIcon(baselinePointOfSale)
  },
  {
    title: 'admin',
    path: '/admin',
    icon: getIcon(baselineAdminPanelSettings),
    children: [
      {
        title: 'activities',
        path: '/admin/activities',
        icon: getIcon(tabletFilled)
      },
      // {
      //   title: 'users',
      //   path: '/admin/users',
      //   icon: getIcon(tabletFilled)
      // },
      {
        title: 'log',
        path: '/admin/log',
        icon: getIcon(tabletFilled)
      }
    ]
  }
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
