// material
import { Box, Card, CardHeader } from '@mui/material';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Team A',
    type: 'area',
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 11]
  },
  {
    name: 'Team B',
    type: 'area',
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 78]
  },
  {
    name: 'Team C',
    type: 'area',
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 5]
  },
  {
    name: 'Team C',
    type: 'area',
    data: [30, 25, 36, 15, 45, 35, 32, 52, 53, 54, 22, 5]
  },
  {
    name: 'Team C',
    type: 'area',
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 5]
  }
];

export default function AppWebsiteVisits({ list }) {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: Array.from({ length: list.length }, (v, k) => 'gradient') },
    labels: list[0].items.map((item) => item.month),
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} sales`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Monthly sales by category" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={list.map((item) => ({
            name: item.category,
            type: 'area',
            data: item.items.map((sales) => sales.sales)
          }))}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
