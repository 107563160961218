import { Button, Container, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../components/ErrorDialog';
import Page from '../components/Page';
import { Alert } from '../components/Snackbar';
import ProductEditImage from '../components/product/ProductEditImage';
import ProductForm from '../components/product/ProductForm';
import { API_URL } from '../constants';
import { StateContext } from '../contexts/state/State';

export default function CreateProduct({ fetchCategories }) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [state, dispatch] = useContext(StateContext);
  const { categories } = state;
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const createProduct = (value) => {
    const body = {
      name: value.name,
      image,
      unit: value.unit,
      price: value.price,
      categories: JSON.stringify(value.categories),
      inventory: parseInt(value.inventory, 10)
    };

    fetch(`${API_URL}/product`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          // TODO Success message of some sort
          setSuccess({
            message: 'Product has been created! 💪'
          });
          response.json().then((json) => navigate(`/products/${json}`));
        } else {
          setError({
            id: '0233',
            message: 'Error creating the product'
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setError({
          id: '0233',
          message: 'Error creating the product'
        });
      });
  };

  return (
    <Page title="Create product | Finca Naundrup">
      <Container>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Create product
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button sx={{ mb: 5 }} onClick={() => navigate(-1)}>
            Go back
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <ProductEditImage image={image} setImage={(image) => setImage(image)} />
          </Grid>
          <Grid item md={6} xs={12} lg={8}>
            <ProductForm categories={categories} onSave={createProduct} />
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={success !== null} autoHideDuration={4000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success ? success.message : ''}
        </Alert>
      </Snackbar>
      <ErrorDialog open={error !== null} onClose={() => setError(null)} error={error} />
    </Page>
  );
}
