import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Card, Chip, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import twotoneOpenInNew from '@iconify/icons-ic/twotone-open-in-new';
import baselineCloudDownload from '@iconify/icons-ic/baseline-cloud-download';
import baselineEmail from '@iconify/icons-ic/baseline-email';
import { Box } from '@mui/system';
import React from 'react';
import { fDate } from '../../utils/formatTime';
import Label from '../Label';
import Page from '../Page';

export default function CustomerPageHistory({ orders }) {
  const infoItem = (title, subTitle) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="body2" color="text.secondary">
        {subTitle}
      </Typography>
      <Typography component="span" variant="h5">
        {title}
      </Typography>
    </Box>
  );

  if (orders === null) {
    return <Typography>There is no registered orders from this customer</Typography>;
  }

  return (
    <Stack spacing={2}>
      {orders.map((order, index) => (
        <Card key={index} sx={{ p: 2 }}>
          <Grid container>
            <Grid item xs={9} md={10}>
              {infoItem(fDate(order.created_at), 'Created')}
            </Grid>
            <Grid xs={3} md={2} item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Label
                  variant="ghost"
                  color={(order.status === 'banned' && 'warning') || 'success'}
                >
                  {order.status.toUpperCase()}
                </Label>
              </Box>
            </Grid>
          </Grid>
          {JSON.parse(order.data).map((orderline, idx) => (
            <Box
              key={idx}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography>{`${orderline.amount}x ${orderline.product.name}`}</Typography>
              <Typography>{`${orderline.amount * orderline.product.price}€`}</Typography>
            </Box>
          ))}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 2
            }}
          >
            <Typography variant="h5">Total price</Typography>
            <Typography variant="h5">{`${order.total_price}€`}</Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {order.device}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {order.transaction_id}
          </Typography>
        </Card>
      ))}
    </Stack>
  );
}
