import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function ProductList({ products, categories, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => (
          <Grid key={product.id} item xs={12} sm={6} md={3}>
            <ShopProductCard product={product} categories={categories} />
          </Grid>
        ))}
    </Grid>
  );
}
