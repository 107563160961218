import baselineRefresh from '@iconify/icons-ic/baseline-refresh';
import { Icon } from '@iconify/react';
// material
import {
  Card,
  Checkbox,
  CircularProgress,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Label from '../components/Label';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { Alert } from '../components/Snackbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { API_URL } from '../constants';
import { StateContext } from '../contexts/state/State';
import { fDate } from '../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Number', alignRight: false },
  { id: 'last_checkout', label: 'Last checkout', alignRight: false },
  { id: 'current_tap', label: 'Current Tap', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === 'name') {
    return order === 'desc'
      ? (a, b) => {
        try {
          const parsedA = parseInt(a[orderBy], 10);
          const parsedB = parseInt(b[orderBy], 10);
          return parsedB - parsedA;
        } catch (error) {
          return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
        }
      }
      : (a, b) => {
        try {
          const parsedA = parseInt(a[orderBy], 10);
          const parsedB = parseInt(b[orderBy], 10);
          return parsedA - parsedB;
        } catch (error) {
          return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
        }
      };
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Billing() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [success, setSuccess] = useState(null);
  const [state, dispatch] = useContext(StateContext);
  const { billings } = state;
  const navigate = useNavigate();

  useEffect(() => {
    fetchBillingCustomers();
  }, []);

  const fetchBillingCustomers = () => {
    fetch(`${API_URL}/customer/tap`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => dispatch({ type: 'billings', billings: json }));
        }
      })
      .catch((e) => console.log(e));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      const newSelecteds = billings.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  if (!billings) {
    return <CircularProgress />;
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - billings.length) : 0;

  const filteredUsers = applySortFilter(billings, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Billing | Finca Naundrup">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h4">Billing</Typography>
            <IconButton
              onClick={() => {
                fetchBillingCustomers();
                setSuccess({
                  message: 'Billings has been refreshed 👍'
                });
              }}
            >
              <Icon icon={baselineRefresh} />
            </IconButton>
          </Stack>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New Customer
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={billings.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={(e) => {
                            if (e.target.tagName === 'TD') {
                              navigate(`/visitors/account/${row.id}`, { state: row });
                            }
                          }}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell>{parseInt(row.name, 10) || row.name}</TableCell>
                          <TableCell>{fDate(row.last_checkout)}</TableCell>
                          <TableCell>{`${row.current_tap}€`}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(row.status === 'banned' && 'error') || 'success'}
                            >
                              {row.status.toUpperCase()}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={billings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Snackbar open={success !== null} autoHideDuration={4000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success ? success.message : ''}
        </Alert>
      </Snackbar>
    </Page>
  );
}
