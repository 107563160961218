import plusFill from '@iconify/icons-eva/plus-fill';
import baselineVisibility from '@iconify/icons-ic/baseline-visibility';
import baselineVisibilityOff from '@iconify/icons-ic/baseline-visibility-off';
import { Icon } from '@iconify/react';
// material
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { filter } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import DeviceDialog from '../components/DeviceDialog';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserMoreMenu } from '../components/_dashboard/user';
import { StateContext } from '../contexts/state/State';
import { fDate } from '../utils/formatTime';
//
import USERLIST from '../_mocks_/user';
import { API_URL } from '../constants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'expires', label: 'Expires', alignRight: false },
  { id: 'assigned_at', label: 'Assigned at', alignRight: false },
  { id: 'password', label: 'Password', alignRight: false },
  { id: 'is_active', label: 'Status', alignRight: true },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Devices() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newDevice, setNewDevice] = useState(false);
  const [state, dispatch] = useContext(StateContext);
  const { devices } = state;

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = () => {
    fetch(`${API_URL}/device`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => dispatch({ type: 'devices', devices: json }));
        }
      })
      .catch((e) => console.log(e));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  if (!devices) {
    return <CircularProgress />;
  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  const filteredUsers = applySortFilter(devices, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Devices | Finca Naundrup">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Devices
          </Typography>
          <Button
            variant="contained"
            onClick={() => setNewDevice(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Device
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;

                      return (
                        <RowItem
                          key={row.id}
                          row={row}
                          isItemSelected={isItemSelected}
                          handleClick={handleClick}
                        />
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={devices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeviceDialog open={newDevice} onClose={() => setNewDevice(false)} />
      </Container>
    </Page>
  );
}

function RowItem({ row, isItemSelected, handleClick }) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TableRow
      key={row.id}
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row.id)} />
      </TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.name.Valid ? row.name.String : 'Not set'}</TableCell>
      <TableCell>{fDate(row.expires)}</TableCell>
      <TableCell align="left">
        {row.assigned_at.Valid ? fDate(row.assigned_at.Time) : 'Not set'}
      </TableCell>
      <TableCell align="left">
        <Stack direction="row" alignItems="center">
          <Typography component="span">{showPassword ? row.password : '******'}</Typography>
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            <Icon icon={showPassword ? baselineVisibilityOff : baselineVisibility} />
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {row.is_active ? (
          <Typography color="primary.main">Online</Typography>
        ) : (
          <Typography color="error.main">Offline</Typography>
        )}
      </TableCell>

      <TableCell align="right">
        <UserMoreMenu />
      </TableCell>
    </TableRow>
  );
}
