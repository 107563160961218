import {
  Button,
  Card,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { Form, FormikProvider, useFormik } from 'formik';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function ProductForm({ categories, product, onSave }) {
  const formik = useFormik({
    initialValues: {
      name: product ? product.name : '',
      price: product ? product.price : '',
      unit: product ? product.unit : '',
      inventory: product ? product.inventory : '',
      categories: product ? JSON.parse(product.categories) : []
    },
    enableReinitialize: true,
    onSubmit: (value) => {
      onSave(value);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type="text"
              label="Product name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              type="number"
              label="Product price"
              {...getFieldProps('price')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              type="text"
              label="Product unit"
              {...getFieldProps('unit')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">Categories</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                {...getFieldProps('categories')}
                // onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Categories" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.title}>
                    {cat.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Box>
          </Stack>
        </Card>
      </Form>
    </FormikProvider>
  );
}
