import baselineGetApp from '@iconify/icons-ic/baseline-get-app';
import { Icon } from '@iconify/react';
import { Button, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';

export default function SalesToolbar({ downloadFile, downloadCsvFile }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
        Sales
      </Typography>
      <Button variant="contained" startIcon={<Icon icon={baselineGetApp} />} onClick={handleClick}>
        Export
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => downloadFile()}>
          <ListItemIcon>
            <Iconify icon="fa6-solid:file-pdf" />
          </ListItemIcon>
          Download pdf
        </MenuItem>
        <MenuItem onClick={() => downloadCsvFile()}>
          <ListItemIcon>
            <Iconify icon="fa6-solid:file-csv" />
          </ListItemIcon>
          Download csv
        </MenuItem>
      </Menu>
    </Stack>
  );
}
