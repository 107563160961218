import { Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import CustomerPageGenerel from '../components/_customer-page/CustomerPageGenerel';
import CustomerPageHistory from '../components/_customer-page/CustomerPageHistory';
import CustomerPageInvoices from '../components/_customer-page/CustomerPageInvoices';
import { API_URL } from '../constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
export default function CustomerPage() {
  const { id } = useParams();
  const { state } = useLocation();
  const [customer, setCustomer] = useState(state ?? {});
  const [orders, setOrders] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //   const formik = useFormik({
  //     initialValues: {
  //       name: product.name,
  //       price: product.price,
  //       unit: product.unit,
  //       inventory: product.inventory
  //     },
  //     enableReinitialize: true,
  //     onSubmit: (value) => {}
  //   });

  //   const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    const fetchItems = () => {
      fetchCustomer();
      fetchInvoices();
    };

    fetchItems();
  }, []);

  if (!customer) {
    return <CircularProgress />;
  }

  const fetchCustomer = () => {
    fetch(`${API_URL}/customer/${id}`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setCustomer(json.customer);
          setOrders(json.orders);
          setCurrentOrders(json.current_orders);
        });
      }
    });
  };

  const fetchInvoices = () => {
    fetch(`${API_URL}/invoice/customer/${customer.id}`, {
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => setInvoices(json));
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Page title={`Customer: ${customer.name} | Finca Naundrup`}>
      <Container>
        <Typography variant="h4" sx={{ mb: 1 }}>
          {`Number: #${customer.name}`}
        </Typography>
        <Button sx={{ mb: 5 }} onClick={() => navigate(-1)}>
          Go back
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Generel" {...a11yProps(0)} />
                <Tab label="Invoices" {...a11yProps(1)} />
                <Tab label="History" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CustomerPageGenerel
                customer={customer}
                orders={orders}
                currentOrders={currentOrders}
                fetchCustomer={fetchCustomer}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CustomerPageInvoices invoices={invoices} fetchInvoices={fetchInvoices} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CustomerPageHistory orders={orders} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              Item Three
            </TabPanel> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
