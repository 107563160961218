import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
  const { variant, ...other } = props;

  const color = variant === 'light' ? '#C1C4D6' : '#5048E5';

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="92"
      viewBox="0 0 350 157"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,157.000000) scale(0.100000,-0.100000)"
        fill="#ad9300"
        stroke="none"
      >
        <path
          d="M10 1065 l0 -425 380 0 380 0 0 425 0 425 -380 0 -380 0 0 -425z
    m406 296 c22 -4 51 -4 64 1 12 5 25 6 27 4 3 -3 -4 -29 -15 -59 -12 -30 -26
    -101 -32 -158 -6 -57 -13 -113 -16 -124 -2 -11 12 14 31 55 76 160 101 205
    141 247 31 32 48 43 71 43 36 0 56 -20 51 -51 -5 -38 -29 -36 -26 3 3 28 -1
    33 -20 36 -54 8 -127 -108 -243 -383 -34 -82 -66 -154 -71 -159 -17 -21 -16 2
    2 40 12 24 24 79 30 144 6 58 15 120 20 139 5 19 8 36 7 37 -1 1 -36 -63 -77
    -143 -87 -172 -129 -223 -184 -223 -29 0 -38 5 -46 24 -11 29 -2 66 16 66 22
    0 28 -22 8 -37 -17 -12 -17 -14 -1 -30 16 -16 19 -16 52 1 35 19 63 58 128
    176 32 59 147 305 147 316 0 2 -16 4 -34 4 -37 0 -64 -27 -99 -96 -15 -29 -15
    -32 0 -30 25 2 27 -29 5 -51 -11 -10 -21 -27 -23 -37 -2 -11 -10 -21 -18 -24
    -10 -3 -11 2 -6 19 3 13 -9 -3 -28 -36 -47 -83 -103 -125 -168 -125 -43 0 -51
    3 -61 25 -8 19 -8 28 2 40 17 21 30 19 30 -5 0 -11 -5 -20 -11 -20 -8 0 -8 -5
    1 -15 37 -45 129 14 181 116 21 41 15 49 -17 24 -27 -21 -40 -18 -20 4 10 11
    28 22 42 25 17 5 33 24 53 65 17 32 42 73 57 90 31 38 23 51 -35 51 -72 0
    -156 -77 -149 -137 7 -62 88 -31 129 49 11 22 23 38 25 35 10 -10 -35 -85 -66
    -111 -39 -32 -75 -34 -95 -3 -29 45 15 130 87 166 45 22 86 25 154 12z"
        />
        <path
          d="M870 1315 c0 -168 1 -175 20 -175 18 0 20 7 20 80 l0 80 65 0 c50 0
    65 3 65 15 0 10 -16 15 -62 17 l-63 3 -3 58 -3 57 71 0 c63 0 70 2 70 20 0 19
    -7 20 -90 20 l-90 0 0 -175z"
        />
        <path
          d="M1120 1316 c0 -170 1 -176 20 -176 20 0 20 5 18 172 -3 155 -5 173
    -20 176 -17 3 -18 -10 -18 -172z"
        />
        <path
          d="M1240 1316 c0 -169 1 -176 20 -176 19 0 20 7 20 141 l0 142 66 -109
    c37 -60 76 -124 87 -142 14 -23 26 -32 41 -30 20 3 21 7 21 173 0 163 -1 170
    -20 170 -19 0 -20 -6 -19 -137 l2 -137 -47 77 c-93 153 -126 197 -149 200
    l-22 3 0 -175z"
        />
        <path
          d="M1655 1476 c-73 -32 -117 -126 -96 -206 22 -87 78 -130 168 -130 60
    0 83 7 83 26 0 10 -15 13 -58 13 -37 -1 -71 5 -91 16 -82 42 -80 199 3 242 20
    11 52 16 89 15 42 -1 57 3 57 12 0 8 -7 17 -16 20 -27 10 -108 6 -139 -8z"
        />
        <path
          d="M1962 1473 c-6 -10 -31 -83 -57 -163 -27 -80 -50 -151 -53 -158 -3
    -10 2 -13 18 -10 18 2 27 15 40 53 l17 50 63 0 63 0 17 -50 c12 -37 22 -51 38
    -53 12 -2 22 1 22 6 0 13 -98 300 -111 323 -12 24 -45 25 -57 2z m54 -113 l22
    -70 -50 0 c-36 0 -49 4 -45 13 2 6 13 38 23 70 10 31 20 57 23 57 3 0 16 -32
    27 -70z"
        />
        <path
          d="M2193 984 c-23 -5 -23 -7 -23 -175 l0 -169 73 0 c140 0 213 69 205
    193 -7 97 -60 145 -169 152 -35 2 -74 1 -86 -1z m147 -49 c45 -23 60 -53 60
    -116 0 -95 -64 -156 -155 -147 l-30 3 -3 138 -3 137 51 0 c28 0 64 -7 80 -15z"
        />
        <path
          d="M2528 983 c-16 -4 -18 -21 -18 -174 0 -162 1 -169 20 -169 18 0 20 7
    20 75 l0 75 33 0 c48 0 65 -18 85 -85 15 -48 23 -61 41 -63 18 -3 22 0 17 15
    -3 10 -10 39 -17 65 -6 26 -19 54 -30 64 -18 16 -17 17 11 44 57 54 29 138
    -51 154 -34 7 -84 6 -111 -1z m127 -47 c23 -17 24 -71 2 -93 -9 -10 -34 -17
    -59 -17 l-43 -1 -3 63 -3 62 44 0 c24 0 52 -6 62 -14z"
        />
        <path
          d="M3132 983 c-22 -4 -22 -5 -20 -171 3 -165 3 -167 26 -170 21 -3 22 0
    22 67 l0 71 40 0 c77 0 122 39 122 106 0 80 -76 119 -190 97z m126 -49 c54
    -38 10 -124 -64 -124 l-34 0 0 70 0 70 38 0 c21 0 48 -7 60 -16z"
        />
        <path
          d="M870 810 c0 -163 1 -170 20 -170 19 0 20 7 20 143 l0 142 29 -53 c16
    -29 55 -93 86 -143 46 -73 62 -89 79 -87 20 3 21 8 24 171 l2 167 -26 0 -26 0
    4 -135 c2 -74 2 -135 1 -135 -1 0 -10 15 -19 33 -9 17 -46 78 -81 135 -53 83
    -70 102 -89 102 l-24 0 0 -170z"
        />
        <path
          d="M1240 823 c-29 -87 -55 -164 -58 -171 -3 -10 2 -13 18 -10 18 2 27
    15 40 53 l17 50 63 0 63 0 17 -50 c12 -37 22 -51 38 -53 12 -2 22 1 22 6 0 8
    -87 268 -105 315 -4 10 -18 17 -35 17 -26 0 -29 -5 -80 -157z m102 49 c9 -32
    20 -66 24 -75 5 -15 -1 -17 -46 -17 -36 0 -50 4 -48 13 20 68 44 137 48 137 3
    0 13 -26 22 -58z"
        />
        <path
          d="M1500 868 c0 -183 24 -228 124 -228 101 0 128 44 134 218 l4 122 -26
    0 -26 0 0 -120 c0 -89 -4 -129 -15 -150 -26 -51 -100 -53 -132 -3 -13 20 -18
    55 -21 149 -4 113 -6 124 -23 124 -17 0 -19 -9 -19 -112z"
        />
        <path
          d="M1830 810 c0 -163 1 -170 20 -170 18 0 20 8 22 143 l3 142 19 -35
    c10 -19 48 -83 85 -142 52 -85 71 -108 89 -108 22 0 22 0 22 170 0 162 -1 170
    -20 170 -18 0 -20 -8 -22 -132 l-3 -133 -28 50 c-16 28 -52 87 -80 132 -44 70
    -56 82 -79 83 l-28 0 0 -170z"
        />
        <path
          d="M2780 863 c0 -94 4 -126 19 -160 24 -51 45 -63 110 -63 41 0 56 5 81
    28 39 34 49 78 50 210 l0 102 -25 0 -25 0 0 -120 c0 -89 -4 -129 -15 -150 -27
    -53 -103 -53 -130 0 -11 21 -15 61 -15 150 l0 120 -25 0 -25 0 0 -117z"
        />
        <path
          d="M125 410 c-14 -27 -29 -50 -34 -50 -5 0 -23 17 -40 37 -17 21 -31 33
    -31 28 0 -5 16 -26 36 -46 l36 -36 -15 -44 c-8 -24 -12 -48 -9 -54 4 -5 9 1
    10 15 2 13 21 64 42 112 42 97 44 115 5 38z"
        />
        <path
          d="M1344 425 c-3 -14 -4 -31 -1 -39 3 -7 0 -19 -6 -25 -8 -8 -8 -11 1
    -11 7 0 12 -9 12 -20 0 -12 8 -35 17 -53 14 -26 18 -29 21 -13 2 10 -1 22 -7
    26 -7 3 -13 19 -14 35 -2 23 3 29 28 36 17 5 42 10 58 11 15 1 27 6 27 11 0 5
    -19 5 -42 1 -81 -14 -83 -13 -81 29 3 42 -4 49 -13 12z"
        />
        <path
          d="M2920 390 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"
        />
        <path
          d="M764 349 c-4 -7 0 -15 8 -20 8 -4 26 -17 39 -28 20 -18 22 -22 9 -31
    -8 -6 -23 -8 -32 -6 -10 2 -18 0 -18 -5 0 -14 44 -11 64 4 17 12 15 15 -19 45
    -20 18 -33 37 -30 42 3 6 2 10 -4 10 -5 0 -13 -5 -17 -11z"
        />
        <path
          d="M2644 349 c-3 -6 9 -24 27 -40 29 -24 32 -30 19 -39 -8 -5 -21 -8
    -27 -5 -7 2 -13 0 -13 -5 0 -15 34 -12 56 5 18 15 18 16 -18 46 -21 17 -35 35
    -32 40 3 5 3 9 0 9 -3 0 -8 -5 -12 -11z"
        />
        <path
          d="M2777 353 c-13 -12 -7 -21 29 -44 34 -21 36 -23 18 -36 -10 -7 -24
    -11 -31 -9 -7 3 -13 1 -13 -4 0 -15 34 -12 56 5 18 14 18 16 -14 45 -18 17
    -30 35 -27 40 7 11 -8 14 -18 3z"
        />
        <path
          d="M547 339 c-9 -5 -22 -7 -27 -4 -6 3 -10 -5 -10 -19 0 -31 9 -56 21
    -56 6 0 5 9 -1 20 -9 17 -7 24 12 40 13 11 29 17 36 15 7 -3 10 0 7 5 -8 12
    -15 12 -38 -1z"
        />
        <path
          d="M1250 341 c-8 -6 -18 -7 -22 -5 -4 3 -8 -11 -8 -30 0 -19 5 -38 11
    -42 8 -4 9 1 4 15 -7 25 8 49 40 61 12 6 14 9 5 9 -8 0 -22 -3 -30 -8z"
        />
        <path
          d="M2075 339 c-4 -6 -13 -8 -21 -5 -17 6 -19 -41 -4 -64 8 -11 9 -8 4
    12 -5 21 -1 30 20 43 14 9 31 13 37 9 8 -4 9 -3 5 4 -9 15 -33 15 -41 1z"
        />
        <path
          d="M2529 339 c22 -9 22 -11 8 -32 -17 -25 -44 -34 -60 -18 -7 7 -5 16 8
    31 10 11 13 20 8 20 -16 0 -35 -27 -31 -46 6 -31 34 -40 67 -21 19 11 32 14
    36 7 3 -5 16 -10 28 -9 20 1 20 2 -3 8 -14 4 -27 16 -29 26 -5 28 -21 45 -40
    44 -10 0 -8 -4 8 -10z"
        />
        <path
          d="M2900 341 c0 -19 33 -80 41 -76 9 6 -1 45 -12 45 -5 0 -9 6 -9 14 0
    8 -4 18 -10 21 -5 3 -10 2 -10 -4z"
        />
        <path
          d="M215 320 c-17 -19 -17 -21 0 -40 38 -42 102 -14 88 38 -7 29 -21 28
    -16 -1 6 -29 -19 -51 -47 -42 -27 8 -26 39 3 53 15 7 17 11 6 11 -9 1 -24 -8
    -34 -19z"
        />
        <path
          d="M353 324 c-10 -27 14 -64 42 -64 31 0 65 33 65 63 -1 19 -3 17 -15
    -10 -23 -55 -93 -52 -82 4 5 26 -1 31 -10 7z"
        />
        <path
          d="M890 322 c0 -15 8 -43 46 -151 2 -8 7 -11 10 -8 3 2 -6 40 -21 82
    -14 43 -24 80 -21 83 8 7 95 -8 101 -19 4 -5 -13 -18 -37 -28 -51 -23 -37 -28
    16 -6 65 27 33 65 -55 65 -31 0 -39 -4 -39 -18z"
        />
        <path
          d="M1088 329 c-24 -13 -23 -46 1 -59 49 -25 89 0 75 47 l-8 28 -5 -35
    c-7 -39 -38 -53 -60 -26 -15 18 -7 34 23 46 12 5 15 9 6 9 -8 0 -23 -4 -32
    -10z"
        />
        <path
          d="M1747 322 c-10 -10 -17 -21 -17 -25 0 -15 34 -37 59 -37 36 0 57 38
    36 67 -13 17 -14 16 -10 -8 7 -38 -17 -56 -53 -40 l-27 13 28 24 c16 14 22 24
    14 24 -8 0 -21 -8 -30 -18z"
        />
        <path
          d="M1881 322 c-2 -36 17 -62 44 -62 31 0 65 33 64 63 0 19 -2 18 -16
    -10 -28 -56 -77 -53 -86 5 -4 24 -4 24 -6 4z"
        />
        <path
          d="M2280 332 c0 -23 54 -175 58 -164 2 7 -6 44 -19 82 -12 39 -20 73
    -16 76 9 10 90 -9 94 -21 2 -6 -13 -17 -33 -24 -19 -7 -33 -15 -31 -17 7 -7
    87 27 87 37 0 4 -10 15 -22 23 -23 17 -118 22 -118 8z"
        />
        <path
          d="M3018 329 c-21 -12 -23 -40 -6 -57 15 -15 61 -15 76 0 15 15 16 54 2
    63 -6 4 -10 -6 -10 -24 0 -36 -33 -52 -57 -28 -18 17 -10 35 21 47 12 5 15 9
    6 9 -8 0 -23 -4 -32 -10z"
        />
        <path
          d="M3140 312 c0 -42 10 -46 42 -15 l28 27 33 -32 c18 -18 38 -32 45 -32
    6 0 -8 18 -32 40 -24 22 -46 40 -49 40 -4 0 -15 -10 -27 -22 l-20 -22 0 22 c0
    12 -4 22 -10 22 -5 0 -10 -13 -10 -28z"
        />
        <path
          d="M1517 258 c-10 -17 -16 -33 -13 -35 2 -3 12 9 21 27 21 41 15 48 -8
    8z"
        />
      </g>
    </svg>
  );
})``;

Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
