import baselineClear from '@iconify/icons-ic/baseline-clear';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../components/ErrorDialog';
// components
import Page from '../components/Page';
import { ProductList } from '../components/_dashboard/products';
import { API_URL } from '../constants';
import { StateContext } from '../contexts/state/State';

// ----------------------------------------------------------------------

export default function EcommerceShop({ fetchCategories }) {
  const navigate = useNavigate();
  const [category, setCategory] = useState('');
  const [error, setError] = useState(null);
  const [state, dispatch] = useContext(StateContext);
  const { products, categories } = state;
  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = () => {
    fetch(`${API_URL}/product`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => dispatch({ type: 'products', products: json }));
        }
      })
      .catch((e) => console.log(e));
  };

  const createCategory = (e) => {
    e.preventDefault();
    fetch(`${API_URL}/category`, {
      method: 'POST',
      body: JSON.stringify({
        title: category
      }),
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          fetchCategories();
          setCategory('');
        }
      })
      .catch((e) => console.log(e));
  };

  const deleteCategory = (id) => {
    const confirm = window.confirm('Are you sure you want to delete the category?');
    if (confirm) {
      fetch(`${API_URL}/category/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token: window.localStorage.getItem('finca_admin_token')
        }
      })
        .then((response) => {
          if (response.ok) {
            fetchCategories();
          } else {
            setError({
              id: '1001',
              message: 'Error happened trying to delete category'
            });
          }
        })
        .catch((e) => console.log(e));
    }
  };

  if (!products) {
    return <CircularProgress />;
  }

  return (
    <Page title="Dashboard: Products | Finca Naundrup">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>

        <Stack spacing={2} sx={{ mb: 5 }}>
          <Card sx={{ p: 2 }}>
            <Typography variant="h6">Categories</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box component="form" onSubmit={createCategory}>
                  <Stack spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
                    <TextField
                      label="Category"
                      placeholder="New category"
                      value={category}
                      onChange={(val) => setCategory(val.target.value)}
                    />
                    <Button type="submit">Add category</Button>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {categories.map((item, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        color="error"
                        edge="end"
                        aria-label="comments"
                        onClick={() => deleteCategory(item.id)}
                      >
                        <Icon icon={baselineClear} />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Grid>
            </Grid>
          </Card>
          <Stack
            direction="row"
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button variant="contained" onClick={() => navigate('/products/create')}>
              Create new
            </Button>
          </Stack>
        </Stack>
        <ProductList products={products} categories={categories} />
      </Container>
      <ErrorDialog open={error !== null} onClose={() => setError(null)} error={error} />
    </Page>
  );
}
