import {
  Button,
  CircularProgress,
  Container,
  DialogActions,
  Stack,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

export default function Loading({ setLoading }) {
  const [dots, setDots] = useState('...');

  useEffect(() => {
    let _dots = dots;
    const interval = setInterval(() => {
      if (_dots.length === 3) {
        _dots = '.';
        setDots(_dots);
      } else if (_dots.length === 1) {
        _dots = '..';
        setDots(_dots);
      } else if (_dots.length === 2) {
        _dots = '...';
        setDots(_dots);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <Stack
        spacing={2}
        sx={{ my: 2, textAlign: 'center' }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
        <Typography>{`Loading data and creating invoice${dots}`}</Typography>
      </Stack>
      <DialogActions>
        <Button onClick={() => setLoading(false)}>Back</Button>
      </DialogActions>
    </Container>
  );
}
