import baselineCloudDownload from '@iconify/icons-ic/baseline-cloud-download';
import baselineEmail from '@iconify/icons-ic/baseline-email';
import baselineLocalPrintshop from '@iconify/icons-ic/baseline-local-printshop';
import { Icon } from '@iconify/react';
import {
  Button,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import printJS from 'print-js';
import { useEffect, useState } from 'react';
import { API_URL } from '../../constants';
import { fDate } from '../../utils/formatTime';
import { Logo } from '../Logo';

const test = {
  id: 4,
  data: '[{"id": 6, "name": "Coca Cola", "price": 6, "amount": 3}, {"id": 7, "name": "Large beer", "price": 9, "amount": 3}, {"id": 8, "name": "Roger de flor cava", "price": 60, "amount": 4}]',
  customer_id: 1,
  created_at: '2022-01-31T13:40:27.046329Z',
  from_date: '2022-01-14T00:02:59Z',
  to_date: '2022-01-31T13:08:15.595Z'
};

const fnGetFileNameFromContentDispostionHeader = (header) => {
  const contentDispostion = header.split(';');
  const fileNameToken = `filename*=UTF-8''`;

  const fileName = 'downloaded.pdf';
  // for (const thisValue of contentDispostion) {
  //   if (thisValue.trim().indexOf(fileNameToken) === 0) {
  //     fileName = decodeURIComponent(thisValue.trim().replace(fileNameToken, ''));
  //     break;
  //   }
  // }

  return fileName;
};

export default function Invoice({ invoice, onClose }) {
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    // Get total price
    getTotalPrice();
  }, []);

  const getTotalPrice = () => {
    setTotalPrice(null);
    let temp = 0;
    JSON.parse(invoice.data).forEach((element) => {
      temp += element.price;
    });
    setTotalPrice(temp);
  };

  const downloadFile = () => {
    fetch(`${API_URL}/invoice/test/${invoice.id}`, {
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    }).then((res) => {
      if (res.ok) {
        res.blob().then((blob) => {
          const b = new Blob([blob], { type: 'application/pdf' });
          const file = window.URL.createObjectURL(b);
          const link = document.createElement('a');
          link.href = file;
          link.download = `invoice-${invoice.id}.pdf`;
          link.click();
        });
      }
    });
  };

  const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob); // or however you want to check it

  const printPDF = (blob) => {
    if (isIE11) {
      window.navigator.msSaveOrOpenBlob(blob, 'invoice.pdf');
    } else {
      printJS(URL.createObjectURL(blob));
    }
  };

  const downloadFileForPrint = () => {
    fetch(`${API_URL}/invoice/test/${invoice.id}`, {
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    }).then((res) => {
      if (res.ok) {
        res.blob().then((blob) => {
          const b = new Blob([blob], { type: 'application/pdf' });
          printPDF(b);
        });
      }
    });
  };

  const sortByName = (a, b) => a.name.localeCompare(b.name);
  return (
    <Container sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5">Finca Naundrup</Typography>
          <Typography>JOSE DE, C. Don José de Orbaneja, S/N</Typography>
          <Typography>29649 Calahonda, Málaga, Spanien</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'end' }}>
          <Logo />
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginLeft: 'auto' }}>
          <Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Invoice #</Typography>
              <Typography>{invoice.id}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Invoice Date</Typography>
              <Typography>{fDate(invoice.created_at)}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Check In Date</Typography>
              <Typography>{fDate(invoice.from_date)}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Check Out Date</Typography>
              <Typography>{fDate(invoice.to_date)}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Qty</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Unit Price (€)</TableCell>
                <TableCell align="right">Amount (€)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {JSON.parse(invoice.data)
                .sort(sortByName)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.price / item.amount}</TableCell>
                    <TableCell align="right">{item.price}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <Typography variant="h6">Subtotal</Typography>
                </TableCell>
                <TableCell align="right">{(totalPrice * 0.8).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <Typography variant="h6">Sales Tax 25%</Typography>
                </TableCell>
                <TableCell align="right">{(totalPrice * 0.2).toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <Typography variant="h5">Invoice Total</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h5">{`${totalPrice.toFixed(2)}€`}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Stack
        direction={{ xs: 'column', sm: 'row', md: 'row' }}
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ py: 2 }}
      >
        <Button
          color="warning"
          variant="contained"
          onClick={downloadFile}
          startIcon={<Icon icon={baselineCloudDownload} />}
          fullWidth
        >
          Download
        </Button>
        <Button
          color="info"
          variant="contained"
          startIcon={<Icon icon={baselineEmail} />}
          fullWidth
        >
          Send as email
        </Button>
        <Button
          variant="contained"
          startIcon={<Icon icon={baselineLocalPrintshop} />}
          fullWidth
          onClick={downloadFileForPrint}
        >
          Print
        </Button>
        <Button variant="contained" color="error" onClick={onClose} fullWidth>
          Close
        </Button>
      </Stack>
    </Container>
  );
}
