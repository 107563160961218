import JwtDecode from 'jwt-decode';
import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { StateContext } from './contexts/state/State';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ActivitiesPage from './pages/admin/activities';
import LogPage from './pages/admin/log';
import Billing from './pages/Billing';
import CreateProduct from './pages/CreateProduct';
import CustomerPage from './pages/Customer';
import DashboardApp from './pages/DashboardApp';
import Devices from './pages/Devices';
//
import { API_URL } from './constants';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import ProductPage from './pages/Product';
import Products from './pages/Products';
import Profile from './pages/Profile';
import Sales from './pages/Sales';
import User from './pages/User';

// ----------------------------------------------------------------------
function PrivateRoute({ children, login }) {
  try {
    const token = window.localStorage.getItem('finca_admin_token');
    const decoded = JwtDecode(token);
    const date = new Date(Date.now());
    if (decoded.exp < date.valueOf() / 1000) {
      window.localStorage.removeItem('finca_admin_token');
      return <Navigate to="/auth/login" />;
    }
    if (login) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  } catch (error) {
    if (login) {
      window.localStorage.removeItem('finca_admin_token');
      return children;
    }
    window.localStorage.removeItem('finca_admin_token');
    return <Navigate to="/auth/login" />;
  }
}
export default function Router() {
  const [_, dispatch] = useContext(StateContext);

  const fetchCategories = () => {
    fetch(`${API_URL}/category`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => dispatch({ type: 'categories', categories: json }));
        }
      })
      .catch((e) => console.log(e));
  };

  return useRoutes([
    {
      path: '/',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        {
          path: 'dashboard',
          element: <DashboardApp />
        },
        { path: 'devices', element: <Devices /> },
        {
          path: 'visitors/customers',
          element: <User />
        },
        {
          path: 'visitors/billing',
          element: <Billing />
        },
        {
          path: 'products',
          element: <Products fetchCategories={fetchCategories} />
        },
        {
          path: 'products/:id',
          element: <ProductPage fetchCategories={fetchCategories} />
        },
        {
          path: 'products/create',
          element: <CreateProduct fetchCategories={fetchCategories} />
        },
        { path: 'visitors/account/:id', element: <CustomerPage /> },
        { path: 'profile', element: <Profile /> },
        {
          path: 'sales',
          element: <Sales fetchCategories={fetchCategories} />
        },
        {
          path: 'admin/log',
          element: <LogPage />
        },
        {
          path: 'admin/activities',
          element: <ActivitiesPage />
        },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/auth',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/auth', element: <Navigate to="/auth/login" /> },
        {
          path: 'login',
          element: (
            <PrivateRoute login>
              <Login />
            </PrivateRoute>
          )
        },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
