import { Button, CircularProgress, Dialog, DialogActions, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { API_URL } from '../constants';

export default function DeviceDialog({ open, onClose }) {
  const [pass, setPass] = useState(null);

  useEffect(() => {
    if (open) {
      fetch(`${API_URL}/device/password`, {
        method: 'GET',
        headers: {
          token: window.localStorage.getItem('finca_admin_token')
        }
      }).then((response) => {
        if (response.ok) {
          response.json().then((json) => setPass(json.password));
        }
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 500
        }}
      >
        {pass !== null ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: 72 }}>{pass}</Typography>
            <Typography variant="body2" color="text.secondary">
              Enter this password on the device you wish to add, along with the desired name for the
              device
            </Typography>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography>Generating a password</Typography>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <DialogActions>
        <Button variant="contained" color="error" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
