import deleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import { Icon } from '@iconify/react';
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Stack,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ErrorDialog from '../components/ErrorDialog';
import Page from '../components/Page';
import { Alert } from '../components/Snackbar';
import ProductEditImage from '../components/product/ProductEditImage';
import ProductForm from '../components/product/ProductForm';
import ProductInventory from '../components/product/ProductInventory';
import { API_URL } from '../constants';
import { StateContext } from '../contexts/state/State';

export default function ProductPage({ fetchCategories }) {
  const { id } = useParams();
  const { state } = useLocation();
  const [product, setProduct] = useState(state ?? null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [stateContext, dispatch] = useContext(StateContext);
  const { categories } = stateContext;
  const navigate = useNavigate();

  const updateImage = (image) => {
    const prod = { ...product };
    prod.image = image;
    setProduct(prod);
  };

  useEffect(() => {
    const fetchInfo = () => {
      fetchCategories();
      fetchProduct();
    };
    fetchInfo();
  }, []);

  const fetchProduct = () => {
    fetch(`${API_URL}/product/${id}`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((json) => {
            setProduct(json);
          })
          .catch((e) =>
            setError({
              id: 1234,
              message: e
            })
          );
      } else {
        response.json().then((json) =>
          setError({
            id: json.code,
            message: json.message
          })
        );
      }
    });
  };

  const editProduct = (value) => {
    const body = {
      id: parseInt(id, 10),
      name: value.name,
      image: product.image,
      unit: value.unit,
      price: value.price,
      categories: JSON.stringify(value.categories),
      inventory: parseInt(value.inventory, 10)
    };

    fetch(`${API_URL}/product`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          // TODO Success message of some sort
          setSuccess({
            message: 'Product has been updated! 👍'
          });
        } else {
          response.json().then((json) =>
            setError({
              id: json.code,
              message: json.message
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
        setError({
          id: '0232',
          message: 'No connection'
        });
      });
  };

  if (!product) {
    return (
      <Page title="Product | Finca Naundrup">
        <Container sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography>Loading product</Typography>
        </Container>
        <ErrorDialog
          open={error !== null}
          onClose={() => {
            setError(null);
            navigate('/products');
          }}
          error={error}
        />
      </Page>
    );
  }

  return (
    <Page title={`Product: ${product.name} | Finca Naundrup`}>
      <Container>
        <Typography variant="h4" sx={{ mb: 1 }}>
          {product.name}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button sx={{ mb: 5 }} onClick={() => navigate(-1)}>
            Go back
          </Button>
          <Button
            startIcon={<Icon icon={deleteOutlined} />}
            color="error"
            sx={{ mb: 5 }}
            onClick={() => {
              const confirm = window.confirm(
                "Are you sure if you want to delete this product\r\nThis action can't be undone"
              );
              if (confirm) {
                fetch(`${API_URL}/product/${id}`, {
                  method: 'DELETE',
                  headers: {
                    token: window.localStorage.getItem('finca_admin_token')
                  }
                })
                  .then((response) => {
                    if (response.ok) {
                      navigate('/products');
                    } else {
                      setError({
                        id: '1234',
                        message: 'There was an error deleting the product'
                      });
                    }
                  })
                  .catch((e) =>
                    setError({
                      id: '1234',
                      message: 'There was an error deleting the product'
                    })
                  );
              }
            }}
          >
            Delete
          </Button>
        </Stack>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <ProductEditImage image={product.image} setImage={updateImage} />
          </Grid>
          <Grid item md={6} xs={12} lg={8}>
            <ProductForm categories={categories} product={product} onSave={editProduct} />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProductInventory id={product.id} product={product} fetchProduct={fetchProduct} />
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={success !== null} autoHideDuration={4000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success ? success.message : ''}
        </Alert>
      </Snackbar>
      <ErrorDialog open={error !== null} onClose={() => setError(null)} error={error} />
    </Page>
  );
}
