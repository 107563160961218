export const reducer = (state, action) => {
  switch (action.type) {
    case 'sales': {
      state = { ...state, sales: action.sales };
      return state;
    }
    case 'categories': {
      state = { ...state, categories: action.categories };
      return state;
    }
    case 'products': {
      state = { ...state, products: action.products };
      return state;
    }
    case 'customers': {
      state = { ...state, customers: action.customers };
      return state;
    }
    case 'orders': {
      state = { ...state, orders: action.orders };
      return state;
    }
    case 'dashboard': {
      state = { ...state, dashboard: action.dashboard };
      return state;
    }
    case 'billings': {
      state = { ...state, billings: action.billings };
      return state;
    }
    case 'devices': {
      state = { ...state, devices: action.devices };
      return state;
    }
    case 'error':
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export const initialState = { error: null, categories: [] };
