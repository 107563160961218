import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useContext, useEffect, useState } from 'react';
import Page from '../components/Page';
import { AppBugReports, AppNewUsers, AppWeeklySales } from '../components/_dashboard/app';
import SalesToolbar from '../components/_sales/SalesToolbar';
import { API_URL } from '../constants';
import { StateContext } from '../contexts/state/State';

const subtractMonth = (date, months) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() - months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export default function Sales({ fetchCategories }) {
  const [fromDate, setFromDate] = useState(subtractMonth(new Date(Date.now()), 1));
  const [toDate, setToDate] = useState(new Date(Date.now()));
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useContext(StateContext);
  const { sales, categories, error } = state;

  useEffect(() => {
    // setLoading(true);
    fetchCategories();
    fetchSales({
      categories: selectedCategory,
      from_date: new Date(fromDate),
      to_date: new Date(toDate)
    });
  }, [fromDate, toDate, selectedCategory]);

  const fetchSales = (body) => {
    fetch(`${API_URL}/sales`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            dispatch({ type: 'sales', sales: json });
            setLoading(false);
          });
        }
      })
      .catch((e) => {
        dispatch({ type: 'error', error: { id: 4001, message: 'Failed to fetch sales' } });
      });
  };

  const setToDefault = () => {
    setFromDate(new Date('2022-01-01'));
    setToDate(Date.now);
    setSelectedCategory('');
  };

  const downloadFile = () => {
    fetch(`${API_URL}/sales/pdf`, {
      method: 'POST',
      body: JSON.stringify({
        categories: selectedCategory,
        from_date: new Date(fromDate),
        to_date: new Date(toDate)
      }),
      headers: {
        token: window.localStorage.getItem('finca_admin_token'),
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      if (res.ok) {
        res.blob().then((blob) => {
          const b = new Blob([blob], { type: 'application/pdf' });
          const file = window.URL.createObjectURL(b);
          const link = document.createElement('a');
          link.href = file;
          link.download = `${Date.now().toString()}.pdf`;
          link.click();
        });
      }
    });
  };
  const downloadCsvFile = () => {
    fetch(`${API_URL}/sales/csv`, {
      method: 'POST',
      body: JSON.stringify({
        categories: selectedCategory,
        from_date: new Date(fromDate),
        to_date: new Date(toDate)
      }),
      headers: {
        token: window.localStorage.getItem('finca_admin_token'),
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      if (res.ok) {
        res.blob().then((blob) => {
          const b = new Blob([blob], { type: 'application/csv' });
          const file = window.URL.createObjectURL(b);
          const link = document.createElement('a');
          link.href = file;
          link.download = `${Date.now().toString()}.csv`;
          link.click();
        });
      }
    });
  };

  if (!sales) {
    return <CircularProgress />;
  }

  return (
    <Page title="Sales | Finca Naundrup" error={error}>
      <Container>
        <SalesToolbar downloadFile={downloadFile} downloadCsvFile={downloadCsvFile} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card>
              <CardHeader title="Sort sales by" />

              <CardContent>
                <Stack spacing={2} direction="column">
                  <Typography variant="overline">By date</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="From date"
                      value={fromDate}
                      onChange={(newValue) => {
                        setFromDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="To date"
                      value={toDate}
                      onChange={(newValue) => {
                        setToDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                  <Divider />
                  <Typography variant="overline">By category</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      label="Category"
                      onChange={(val) => setSelectedCategory(val.target.value)}
                    >
                      {categories.map((item) => (
                        <MenuItem key={item.id} value={item.title}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <Divider /> */}
                  {/* <Typography variant="overline">By product</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Product</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      label="Product"
                      onChange={(val) => setSelectedCategory(val)}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl> */}
                  {/* <Button variant="contained">Sort</Button> */}
                  <Button variant="contained" color="error" onClick={() => setToDefault()}>
                    Clear
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <AppWeeklySales title="Sales" value={sales.sales} />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppNewUsers title="Revenue" value={sales.revenue} />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppBugReports title="Daily average" value={sales.daily_average} />
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell align="right">Sales</TableCell>
                          <TableCell align="right">Revenue</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sales.items != null ? (
                          sales.items
                            .sort((a, b) => a.id - b.id)
                            .map((item) => (
                              <TableRow key={item.id + item.title}>
                                <TableCell>{loading ? <Skeleton /> : item.id}</TableCell>
                                <TableCell>{loading ? <Skeleton /> : item.title}</TableCell>
                                <TableCell align="right">
                                  {loading ? <Skeleton /> : item.sales}
                                </TableCell>
                                <TableCell align="right">
                                  {loading ? <Skeleton /> : `${item.revenue}€`}
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Typography>No products bought within the seach query</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <AppWebsiteVisits />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
