import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function AccountProfile(props) {
  const { user } = props;
  const navigate = useNavigate();
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            sx={{
              height: 64,
              mb: 2,
              width: 64,
              bgcolor: 'primary.main'
            }}
          >
            {user.name.split(' ').reduce((prev, newValue) => (prev += newValue.charAt(0)), '')}
          </Avatar>
          <Typography color="textPrimary" gutterBottom variant="h5">
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Finca Naundrup
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {user.timezone}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="error"
          fullWidth
          variant="text"
          onClick={() => {
            window.localStorage.removeItem('finca_admin_token');
            navigate('/auth/login');
          }}
        >
          Sign out
        </Button>
      </CardActions>
    </Card>
  );
}
