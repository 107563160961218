import { Dialog } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { API_URL } from '../constants';
import ErrorDialog from './ErrorDialog';
import CheckoutDialogConfirm from './_checkout-dialog/CheckoutDialogConfirm';
import Invoice from './_checkout-dialog/Invoice';
import Loading from './_checkout-dialog/Loading';

export default function CheckoutDialog({ open, onClose, customerId, from, to }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [fromPicker, setFromPicker] = useState(from);
  const [toPicker, setToPicker] = useState(to);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(false);
    setInvoice(null);
  }, [open]);

  const formik = useFormik({
    initialValues: {
      checked: orders
    },
    onSubmit: async () => {
      setLoading(true);
      const temp = [];
      orders.forEach((element) => {
        const datas = [];
        element.data.forEach((json) => {
          if ((json.status === 'active' || json.status === undefined) && json.amount > 0) {
            datas.push(json);
          }
        });
        if (datas.length > 0) {
          element.data = JSON.stringify(datas);
          temp.push(element);
        }
      });
      if (temp.length > 0) {
        const combinedOrders = combineOrders(temp);
        const inv = await createInvoice(combinedOrders);
        setInvoice(inv);
        setLoading(false);
      } else {
        setLoading(false);
        setError({
          id: '0404',
          message: 'Cannot create invoice with no items'
        });
      }
    },
    enableReinitialize: true
  });

  const combineOrders = (orders) => {
    const temp = [];
    orders.forEach((element) => {
      const data = JSON.parse(element.data);
      data.forEach((el) => {
        const objIdx = getObjectIndex(temp, el);
        if (objIdx !== null) {
          temp[objIdx].amount += el.amount;
          temp[objIdx].price += el.amount * el.product.price;
        } else {
          temp.push({
            id: el.product.id,
            name: el.product.name,
            amount: el.amount,
            price: el.product.price * el.amount
          });
        }
      });
    });
    return temp;
  };

  const getObjectIndex = (list, object) => {
    let idx = null;
    list.forEach((element, index) => {
      if (element.id === object.product.id) {
        idx = index;
      }
    });
    return idx;
  };

  const checkIfListContainsObject = (list, object) => {
    let res = false;
    list.forEach((el) => {
      if (el.id === object.product.id) {
        res = true;
      }
    });
    return res;
  };

  const createInvoice = async (data) => {
    let _incvoice = null;
    const response = await fetch(`${API_URL}/invoice`, {
      method: 'POST',
      body: JSON.stringify({
        data: JSON.stringify(data),
        customer_id: customerId,
        from_date: fromPicker,
        to_date: toPicker
      }),
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    });
    if (response.ok) {
      const json = await response.json();
      _incvoice = json;
    }
    return _incvoice;
  };

  if (loading) {
    return <Loading setLoading={setLoading} />;
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        {invoice !== null ? (
          <Invoice invoice={invoice} onClose={onClose} />
        ) : (
          <CheckoutDialogConfirm
            customerId={customerId}
            from={fromPicker}
            setFromPicker={setFromPicker}
            to={toPicker}
            setToPicker={setToPicker}
            formik={formik}
            orders={orders}
            setOrders={setOrders}
          />
        )}
      </Dialog>
      <ErrorDialog open={error !== null} onClose={() => setError(null)} error={error} />
    </>
  );
}
