import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { API_URL } from '../../constants';
import { fDateTime } from '../../utils/formatTime';

export default function ActivitiesPage() {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/activities?page=1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => setActivities(json));
        }
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <Page>
      <Container>
        <Typography variant="h3">Activities</Typography>
        <Typography variant="subtitle2">Latest 25 activities</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Table</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.event}</TableCell>
                <TableCell>{item.value}</TableCell>
                <TableCell>{fDateTime(item.created_at)}</TableCell>
                <TableCell>{item.user_id}</TableCell>
                <TableCell>{item.table}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Page>
  );
}
