import { Dialog } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Invoice from './_checkout-dialog/Invoice';

export default function InvoiceDialog({ open, onClose, invoice }) {
  if (invoice === null) {
    return <Box />;
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Invoice invoice={invoice} onClose={onClose} />
    </Dialog>
  );
}
