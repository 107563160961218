import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { API_URL } from '../../constants';
import { fDate } from '../../utils/formatTime';

export default function ProductInventoryHistoryDialog({ open, onClose, product }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/inventory/${product.id}`, {
      method: 'GET',
      headers: {
        token: window.localStorage.getItem('finca_admin_token')
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => setItems(json));
        }
      })
      .catch((e) => console.log(e));
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>History</DialogTitle>
      <DialogContent>
        {items ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Stock before event</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell align="right">Difference from last stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.event}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.stock_before_event}</TableCell>
                  <TableCell>{fDate(item.timestamp)}</TableCell>
                  {item.event === 'stock' ? (
                    <TableCell align="right">{item.amount - item.stock_before_event}</TableCell>
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>Unable to find any events for the product</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
