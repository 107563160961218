import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import baselineHelp from '@iconify/icons-ic/baseline-help';
import { Icon } from '@iconify/react';
import { fDate, fDateTime } from '../../utils/formatTime';
import IconWithTooltip from '../IconWithTooltip';
import ProductInventoryDialog from './ProductInventoryDialog';
import ProductInventoryHistoryDialog from './ProductInventoryHistoryDialog';

export default function ProductInventory({ id, product, fetchProduct }) {
  const [date, setDate] = useState(new Date(Date.now()));
  const [amount, setAmount] = useState(0);
  const [dialog, setDialog] = useState(null);
  const [history, setHistory] = useState(false);
  const [lines, setLines] = useState([]);

  if (!product.last_stock_timestamp) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card sx={{ p: 2 }}>
        <Typography variant="h4">Inventory</Typography>
        <Stack>
          <ListItem
            disablePadding
            secondaryAction={
              <IconWithTooltip
                icon={baselineHelp}
                title="Latest ('Set Stock' + 'Refills') - 'Sold products' is the In Stock"
              />
            }
          >
            <ListItemText
              primary={<Typography variant="body2">In stock</Typography>}
              secondary={
                <Typography variant="h5" color="primary.main">
                  {product.inventory}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            disablePadding
            secondaryAction={
              <IconWithTooltip
                icon={baselineHelp}
                title="Amount of sold products since the last stock count"
              />
            }
          >
            <ListItemText
              primary={<Typography variant="body2">Sold since last count</Typography>}
              secondary={
                <Typography variant="h5" color="error">
                  {product.sold_products_since_stock}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            disablePadding
            secondaryAction={
              <IconWithTooltip icon={baselineHelp} title="Date of the last set stock" />
            }
          >
            <ListItemText
              primary={<Typography variant="body2">Last stock count</Typography>}
              secondary={
                <Typography variant="h5">{fDate(product.last_stock_timestamp)}</Typography>
              }
            />
          </ListItem>
          <ListItem
            disablePadding
            secondaryAction={
              <IconWithTooltip
                icon={baselineHelp}
                title="Last calculated stock - set stock = difference"
              />
            }
          >
            <ListItemText
              primary={<Typography variant="body2">Difference after count</Typography>}
              secondary={
                <Typography variant="h5" color="error.main">
                  {product.difference}
                </Typography>
              }
            />
          </ListItem>
          <Stack spacing={2}>
            <Button
              variant="contained"
              onClick={() =>
                setDialog({
                  title: 'Refill',
                  subtitle: 'Set how many items that has been added to the product inventory',
                  event: 'fill'
                })
              }
            >
              Refill
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                setDialog({
                  title: 'Set stock',
                  subtitle: 'Set how many items there is in the inventory',
                  event: 'stock'
                })
              }
            >
              Set stock
            </Button>
            <Button variant="contained" onClick={() => setHistory(true)}>
              History
            </Button>
          </Stack>
        </Stack>
      </Card>
      <ProductInventoryDialog
        open={dialog !== null}
        onClose={() => setDialog(null)}
        title={dialog !== null ? dialog.title : ''}
        subtitle={dialog !== null ? dialog.subtitle : ''}
        event={dialog !== null ? dialog.event : ''}
        product={product}
        fetchProduct={fetchProduct}
      />
      <ProductInventoryHistoryDialog
        open={history}
        onClose={() => setHistory(false)}
        product={product}
      />
    </>
  );

  // return (
  //   <Card sx={{ p: 2 }}>
  //     <Stack spacing={2}>
  //       <Typography variant="h4">Inventory</Typography>
  //       <Box component="form" onSubmit={handleAdd}>
  //         <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center">
  //           <LocalizationProvider dateAdapter={AdapterDateFns}>
  //             <DateTimePicker
  //               renderInput={(props) => <TextField {...props} />}
  //               label="Timestamp"
  //               value={date}
  //               onChange={(newValue) => {
  //                 setDate(newValue);
  //               }}
  //             />
  //           </LocalizationProvider>
  //           <TextField
  //             label="Amount"
  //             type="number"
  //             value={amount}
  //             onChange={(val) => setAmount(val.target.value)}
  //           />
  //           <Button type="submit" variant="contained">
  //             Add
  //           </Button>
  //         </Stack>
  //       </Box>
  //       {lines.map((item, index) => (
  //         <InventoryLines key={index} item={item} />
  //       ))}
  //     </Stack>
  //   </Card>
  // );
}

function InventoryLines({ item }) {
  return (
    <Card sx={{ p: 2 }}>
      <Grid container direction={{ xs: 'column-reverse', md: 'row' }}>
        {item.latest_amount > 0 ? (
          <Grid item xs={12} md={4}>
            <ListItemText
              sx={{ textAlign: { xs: 'center', md: 'start' } }}
              primary={<Typography variant="h6">{fDateTime(item.latest_date)}</Typography>}
              secondary={<Typography color="success.main">{`+${item.latest_amount}`}</Typography>}
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={4} />
        )}
        <Grid item xs={12} md={4}>
          {item.latest_amount > 0 ? (
            <ListItemText
              sx={{ textAlign: 'center' }}
              primary={<Typography variant="h6">Sold</Typography>}
              secondary={<Typography color="error.main">{`-${item.sold}`}</Typography>}
            />
          ) : (
            <Box />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <ListItemText
            sx={{ textAlign: { xs: 'center', md: 'end' } }}
            primary={<Typography variant="h6">{fDateTime(item.newest_date)}</Typography>}
            secondary={<Typography color="success.main">{`+${item.newest_amount}`}</Typography>}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
