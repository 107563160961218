import { Icon } from '@iconify/react';
import { ClickAwayListener } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';

export default function IconWithTooltip({ icon, title }) {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        describeChild
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        title={title}
      >
        <Icon width={24} onClick={handleTooltipOpen} icon={icon} />
      </Tooltip>
    </ClickAwayListener>
  );
}
