import { Button, Dialog, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";
import Iconify from "../Iconify";
import { useState } from "react";

export default function OrderDialog({ order, open, onClose, onDelete, onEdit }) {
    if (!order) return null;
    const [editOrder, setEditOrder] = useState(order);

    return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent>
            <Stack spacing={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h3">Edit order</Typography>
                    <Button onClick={onDelete} startIcon={<Iconify icon="eva:trash-2-fill" />} color="error" variant="contained">Delete</Button>
                </Stack>
                <Stack spacing={2}>
                    <TextField value={order.product.name} disabled label="Product" fullWidth />
                    <TextField type="number" value={editOrder.amount} label="Amount" fullWidth onChange={(e) => setEditOrder({ ...editOrder, amount: parseInt(e.target.value) })} />
                    <TextField type="number" value={editOrder.product.price} label="Unit price" fullWidth InputProps={{
                        endAdornment: <Typography variant="h6">{editOrder.product.unit}</Typography>
                    }} onChange={(e) => setEditOrder({ ...editOrder, product: { ...editOrder.product, price: parseFloat(e.target.value) } })} />
                </Stack>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="error" onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={() => onEdit(editOrder)}>Save</Button>
        </DialogActions>
    </Dialog>
}