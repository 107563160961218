import { Alert, Grid, Snackbar, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { API_URL } from '../../constants';
import { fDate } from '../../utils/formatTime';
import Label from '../Label';
import ConfigureCheckout from './ConfigureCheckout';
import CustomerOrderItem from './CustomerOrderItem';

export default function CustomerPageGenerel({ customer, orders, currentOrders, fetchCustomer }) {
  const [success, setSuccess] = useState(null)
  const infoItem = (title, subTitle) => (
    <Stack direction="column" sx={{ mb: 2 }}>
      <Box>
        <Typography component="span" variant="body2" color="text.secondary">
          {subTitle}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5">{title}</Typography>
      </Box>
    </Stack>
  );

  const onChange = async (newValue, orderId, task) => {
    if (newValue.length === 0) {
      // Delete order
      const response = await fetch(`${API_URL}/order/${orderId}`, {
        method: 'DELETE',
        headers: {
          token: window.localStorage.getItem('finca_admin_token'),
        }
      })
      if (response.ok) {
        setSuccess({ message: 'Order deleted' })
        fetchCustomer();
      }
      return;
    }
    // Update order
    const response = await fetch(`${API_URL}/order/data/${orderId}`, {
      method: 'PUT',
      body: JSON.stringify(newValue),
      headers: {
        token: window.localStorage.getItem('finca_admin_token'),
        "Content-Type": 'application/json'
      }
    })
    if (response.ok) {
      if (task === "delete") {
        setSuccess({ message: 'Order deleted' })
      } else if (task === "update") {
        setSuccess({ message: 'Order updated' })
      }
      console.log("Updated")
      fetchCustomer();
    }
    return;
  }

  return (
    <Stack spacing={2}>
      {/* <Card sx={{ p: 2 }}> */}
      <ConfigureCheckout customer={customer} fetchCustomer={fetchCustomer} />
      <Grid container>
        <Grid item xs={9} md={10}>
          {infoItem(fDate(customer.last_checkout), 'Last checkout')}
          {infoItem(`${customer.current_tap}€`, 'Current tap')}
        </Grid>
        <Grid xs={3} md={2} item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Label variant="ghost" color={(customer.status === 'banned' && 'warning') || 'success'}>
              {customer.status.toUpperCase()}
            </Label>
          </Box>
        </Grid>
        {
          currentOrders !== null &&
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>

                  <TableCell>Id</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell align="right">Total Price</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {currentOrders.map((order) => {
                  return <CustomerOrderItem key={'customer-order-item-' + order.id} order={order} orderId={order.id} onChange={onChange} />
                })}
              </TableBody>
            </Table>
          </Grid>
        }
      </Grid>
      <Snackbar open={success !== null} autoHideDuration={4000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success ? success.message : ''}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
