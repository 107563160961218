import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { fDate, fTime } from '../../utils/formatTime';

TaskItem.propTypes = {
  task: PropTypes.object,
  checked: PropTypes.bool,
  formik: PropTypes.object
};

export default function TaskItem({ task, formik, index, values, updateTask, ...other }) {
  let localValues = [];

  try {
    localValues = JSON.parse(task.data);
  } catch (error) {
    localValues = task.data;
  }

  const updateValue = (item, value) => {
    item.status = value ? 'active' : 'inactive';
    task.data = localValues;
    getTotalPriceAndUpdate();
  };
  const AddAmount = (item) => {
    item.amount += 1;
    task.data = localValues;
    getTotalPriceAndUpdate();
  };
  const SubAmount = (item, value) => {
    item.amount -= 1;
    task.data = localValues;
    getTotalPriceAndUpdate();
  };

  const getTotalPriceAndUpdate = () => {
    let temp = 0;
    localValues.forEach((element) => {
      if (element.status === 'active' || element.status === undefined) {
        temp += element.product.price * element.amount;
      }
    });
    task.total_price = temp;
    updateTask(task);
  };

  return (
    <>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between" sx={{ py: 0.75 }}>
          <Typography variant="h5">{`${fDate(task.created_at)} - ${fTime(
            task.created_at
          )}`}</Typography>
        </Stack>
        {localValues.map((item, idx) => {
          let checked;
          if (item.status === undefined) {
            checked = true;
          } else if (item.status === 'active') {
            checked = true;
          } else {
            checked = false;
          }
          if (item.amount === 0) {
            checked = false;
          }
          return (
            <Stack direction="row" justifyContent="space-between" sx={{ py: 0.75 }} key={idx}>
              <FormControlLabel
                control={
                  <Checkbox
                    // {...getFieldProps('checked')}
                    onChange={() => updateValue(item, !checked)}
                    value={item}
                    checked={checked}
                    {...other}
                  />
                }
                label={
                  <Stack
                    sx={{
                      ...(!checked && {
                        color: 'text.disabled',
                        textDecoration: 'line-through'
                      })
                    }}
                  >
                    <Typography>{item.product.name}</Typography>
                    <Typography color="text.secondary">{`${item.product.price}${item.product.unit}`}</Typography>
                  </Stack>
                }
              />
              <Stack
                sx={{
                  ...(!checked && {
                    color: 'text.disabled'
                  })
                }}
                direction="row"
                alignItems="center"
              >
                <Button disabled={item.amount === 0} onClick={() => SubAmount(item)}>
                  -
                </Button>
                <Typography>{item.amount}</Typography>
                <Button onClick={() => AddAmount(item)}>+</Button>
              </Stack>
            </Stack>
          );
        })}
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" color="text.secondary">
            Subtotal:{' '}
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
          >{`${task.total_price}${task.unit}`}</Typography>
        </Stack>
      </Stack>
    </>
  );
}
