import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { fDate } from '../../utils/formatTime';
import InvoiceDialog from '../InvoiceDialog';

export default function CustomerPageInvoices({ invoices, fetchInvoices }) {
  const [openInvoice, setOpenInvoice] = useState(null);

  useEffect(() => {
    fetchInvoices();
  }, []);

  if (invoices === null) {
    return <Typography>There is no registered invoices from this customer</Typography>;
  }

  return (
    <Stack spacing={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Invoice#</TableCell>
            <TableCell>Created</TableCell>
            <TableCell align="right">From</TableCell>
            <TableCell align="right">To</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices
            .sort((a, b) => b.id - a.id)
            .map((invoice, index) => (
              // <Card key={index}>
              //   <CardActionArea sx={{ p: 2 }} onClick={() => setOpenInvoice(invoice)}>
              //     <Typography
              //       variant="body2"
              //       color="text.secondary"
              //     >{`Invoice #${invoice.id}`}</Typography>
              //     <Typography variant="h4">{`${fDate(invoice.from_date)} - ${fDate(
              //       invoice.to_date
              //     )}`}</Typography>
              //     <Typography variant="body2" color="text.secondary">{`${fDate(
              //       invoice.created_at
              //     )}`}</Typography>
              //   </CardActionArea>
              // </Card>
              <TableRow
                key={invoice.id}
                onClick={() => setOpenInvoice(invoice)}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                hover
              >
                <TableCell>{invoice.id}</TableCell>
                <TableCell>{fDate(invoice.created_at)}</TableCell>
                <TableCell align="right">{fDate(invoice.from_date)}</TableCell>
                <TableCell align="right">{fDate(invoice.to_date)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
        <InvoiceDialog
          open={openInvoice !== null}
          onClose={() => setOpenInvoice(null)}
          invoice={openInvoice}
        />
      </Table>
    </Stack>
  );
}
