import { IconButton, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import Iconify from "../Iconify";
import OrderDialog from "./OrderDialog";

export default function CustomerOrderItem({ order, onChange, orderId }) {
    if (!order) return null;
    const [parsedOrder, setParsedOrder] = useState(JSON.parse(order.data));
    const [openOrder, setOpenOrder] = useState(null);

    const onDelete = () => {
        onChange((parsedOrder.filter((o, index) => o.id !== openOrder.id)), orderId, "delete");
        setOpenOrder(null);
    }

    const onEdit = (newOrder) => {
        const newParsedOrder = parsedOrder.map((o, index) => {
            if (o.id === newOrder.id) {
                return newOrder;
            }
            return o;
        })
        setParsedOrder(newParsedOrder);
        onChange(newParsedOrder, orderId, "update");
        setOpenOrder(null);
    }

    return parsedOrder.map((o, i) => {
        return <TableRow key={"parsed-order-" + o.id}>
            <TableCell>{o.id}</TableCell>
            <TableCell>{o.amount}</TableCell>
            <TableCell>{o.product.name}</TableCell>
            <TableCell>{o.product.price}</TableCell>
            <TableCell align="right">{o.amount * o.product.price}</TableCell>
            <TableCell align="right"><IconButton onClick={() => setOpenOrder(o)}><Iconify icon="eva:edit-fill" /></IconButton></TableCell>
            <OrderDialog order={openOrder} open={openOrder !== null} onClose={() => setOpenOrder(null)} onDelete={onDelete} onEdit={onEdit} />
        </TableRow>
    });

}